import React, { useContext, useRef, useEffect, useState } from 'react';
import {
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiCard,
  EuiI,
  EuiIcon,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiTextColor,
  EuiText,
  EuiHorizontalRule,
  EuiButtonEmpty,
  EuiFlexGrid,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
} from '@edhos/ui';
import Moment from 'react-moment';
import { AppContext } from '../../../context/app-context';
import { DateInput, IdentityNumberInput, TextInput } from '../../../components';
import TransactionInput from '../../../components/TransactionInput';
import global from '../../../services/global';
import api from '../../../services/api';
import TemplatePrintPoliklinik from '../../../components/TemplatePrintPoliklinik';
import { convertIntToRm, debounce } from '../../../Helper';
import image1 from '../../../assets/image/1.png';
import image2 from '../../../assets/image/2.png';
import image3 from '../../../assets/image/3.png';
import image4 from '../../../assets/image/4.png';

const Two = function (props) {
  const context = useContext(AppContext);
  const [isModalGuideWhatsNextVisible, setIsModalGuideWhatsNextVisible] =
    useState(false);
  const [timeLeft, setTimeLeft] = useState(8);
  const [loadingGetAntrian, setLoadingGetAntrian] = useState(false);
  const [isLoadingRegisterNumber, setLoadingRegisterNumber] = useState(false);
  const [searchingNoRM, setSearchingNoRM] = useState(false);
  const [dataAppointment, setDataAppointment] = useState([]);

  const [isDobFilled, setIsDobFilled] = useState(false);
  const [errorDob, setErrorDob] = useState([]);
  const [isFullnameFilled, setIsFullNameFilled] = useState(false);
  const [isAllowToCheckin, setIsAllowToCheckin] = useState(true);

  const full_name_ref = useRef(null);
  const norm_ref = useRef(null);
  const invNumber_ref = useRef(null);

  const identity_number_ref = useRef(null);
  const dob_ref = useRef(null);
  let modalGuideWhatsNext, intervalGuide, errorTransaction, errorKTP;

  // invNumber_ref.current.focus();

  const startTimerGuide = () => {
    intervalGuide = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft < 1) {
      clearInterval(timeLeft);

      context.reset();
      setDataAppointment([]);
      props.swiperSlideTo(0);
    }
  }, [timeLeft]);

  const deactiveNumpadNKeyboard = () => {
    context.deactiveNumpad();
    context.deactiveKeyboard();
  };

  const onBlur = () => {
    // context.deactiveNumpad();
    // console.log("blur");
  };

  useEffect(() => {
    if (context.onlineCheckin.bookingVia === 'web') {
      setTimeout(() => {
        invNumber_ref.current.focus();
      }, 500);
    }
  }, []);

  useEffect(() => {
    // if (context.regular.invNumber.length > 15) {
    // change in keyboard virtual
    console.log('change in keyboard virtual');
    debouncedSave(context.regular.invNumber);
    // }
  }, [context.regular.invNumber]);

  const handleInvNumber = ({ target: { value } }) => {
    // if (value.length > 15) {
    context.regular.setInvNumber(value);
    debouncedSave(value);
    // }
  };

  const debouncedSave = useRef(
    debounce((nextValue) => checkNumberRegis(nextValue), 1000)
  ).current;

  // function debounce(func, wait) {
  //   let timeout;
  //   return function () {
  //     const context = this;
  //     const args = arguments;
  //     const later = function () {
  //       timeout = null;
  //       func.apply(context, args);
  //     };
  //     clearTimeout(timeout);
  //     timeout = setTimeout(later, wait);
  //   };
  // }

  const handleDob = ({ target: { value } }) => {
    const day = value.split('-')[0];
    const month = value.split('-')[1];
    const year = value.split('-')[2];
    const date = `${year}-${month}-${day}`;

    if (context.moment(date).diff(context.moment()) < 0 && context.moment(date).diff(context.moment(), 'years') > -120) {
      setIsDobFilled(true);
      deactiveNumpadNKeyboard();
      setErrorDob([])
    } else {
      setIsDobFilled(false);
      setErrorDob(['Format tanggal tidak sesuai!'])
    }

    context.regular.setDob(value);
  };

  const handleOnFocusInvNumber = ({ target: { value } }) => {
    context.setDefaultValueText(value);
  };

  const handleOnFocusFullName = ({ target: { value } }) => {
    context.setDefaultValueText(value);
  };

  const handleFullName = ({ target: { value } }) => {
    context.regular.setFullName(value);

    if (value.length > 2) {
      setIsFullNameFilled(true);
    } else {
      setIsFullNameFilled(false);
    }
  };

  const checkNumberRegis = async (nextValue) => {
    console.log('checkNumberRegis', nextValue.length);
    if (nextValue.length > 20) {
      context.setWasSearchMedrek(false);
      const keyword = nextValue.includes('APTINV')
        ? nextValue
        : `APTINV${nextValue}`;
      setLoadingRegisterNumber(true);
      try {
        const { data } = await global.get(
          `transaksi/appointment/cari?code=${keyword}`
        );
        setLoadingRegisterNumber(false);
        setDataAppointment(data.results);
        // invNumber_ref.current.blur();
        // invNumber_ref.current.value("");
      } catch (e) {
        if (e.message === 'Request failed with status code 404') {
          console.log('notfound', e.message);
          setDataAppointment([]);
          setLoadingRegisterNumber(false);
          // context.regular.setInvNumber(nextValue);
        }
      } finally {
        // invNumber_ref.current.blur();
        // document.getElementsByTagName("body")[0].click();
        context.setWasSearchMedrek(true);
        context.regular.setInvNumber('');

        console.log('context.regular.invNumber', context.regular.invNumber);
      }
    }
  };

  const checkNoRM = async () => {
    let dob = context.regular.dob;

    const day = context.regular.dob.slice(0, 2);
    const month = context.regular.dob.slice(2, 4);
    const year = context.regular.dob.slice(4, 8);
    const date = `${year}-${month}-${day}`;

    if (context.moment(date).diff(context.moment()) < 0 && context.moment(date).diff(context.moment(), 'years') > -120) {
      setErrorDob([])
      setSearchingNoRM(true);
      context.setWasSearchMedrek(false);
      try {
        if (!dob.includes('-')) {
          dob = context.parsedDate(dob);
        }
        const { data } = await api.get(
          // `edelweiss.appointment.transaction/check_valid_rm?no_rm=${context.payment.norm}&dob=${dob}&nik=${context.regular.identityNumber}`
          `edelweiss.appointment.transaction/query_patient_appointment?nama=${context.regular.fullName
          }&dob=${dob}&tgl_janji=${context.moment().format('YYYY-MM-DD')}`
        );
        setSearchingNoRM(false);
        if (data?.message === 'not found') {
          context.setDataPatient(null);
        } else {
          // context.setDataPatient(data);
          setDataAppointment(data.results);
          deactiveNumpadNKeyboard();
        }
      } catch (e) {
        setSearchingNoRM(false);
        context.setDataPatient(null);
      }
      context.setWasSearchMedrek(true);
    } else {
      setErrorDob(['Format tanggal tidak sesuai!'])
    }
  };

  if (context.wasSearchMedrek && dataAppointment.length === 0) {
    errorTransaction = [
      'Data Perjanjian tidak ditemukan! Hubungi tim Customer Service kami untuk informasi lebih lanjut',
    ];
  }

  if (context.wasSearchMedrek && context.dataPatient === null) {
    errorKTP = [
      'Data Perjanjian tidak ditemukan! Hubungi tim Customer Service kami untuk informasi lebih lanjut',
    ];
  }

  const printLabel = (appointment) => {
    context.regular.setKlinik({
      name: appointment.poli.name,
      lantai: appointment.poli.lantai,
      area: appointment.poli.area,
    });

    const dob = appointment.patient.dob;
    const dateBirth = `${dob.split('-')[2]}/${dob.split('-')[1]}/${dob.split('-')[0]
      }`;

    const norm = appointment.patient.id_rm;

    window.open(
      `/print-label.html?patient_name=${appointment.patient.name
      }&norm=${`${dateBirth} | ${norm}`}&date=${context
        .moment()
        .format('DD MMMM YYYY HH:mm:ss')}&clinic=${appointment.poli.name
      }&docter=${appointment.doctor[1]}&noantrian=${appointment.no_antrian
      }&lantai=${appointment.poli.lantai}&area=${appointment.poli.area
      }&petunjuk=Silahkan menuju Nurse Station di`,
      'name',
      'width=10,height=10'
    );

    //print twice
    window.open(
      `/print-label.html?patient_name=${appointment.patient.name
      }&norm=${`${dateBirth} | ${norm}`}&date=${context
        .moment()
        .format('DD MMMM YYYY HH:mm:ss')}&clinic=${appointment.poli.name
      }&docter=${appointment.doctor[1]}&noantrian=${appointment.no_antrian
      }&lantai=${appointment.poli.lantai}&area=${appointment.poli.area
      }&petunjuk=Silahkan menuju Nurse Station di`,
      'tab2',
      'width=10,height=10'
    );
    checkInPatient(`?code=${appointment.code}`);
    setIsModalGuideWhatsNextVisible(true);
    startTimerGuide();
  };

  const checkInPatient = async (param) => {
    // /api/edelweiss.appointment.transaction/check_in?code=<kode_booking>
    setLoadingGetAntrian(true);
    const { data } = await api.get(
      // `edelweiss.appointment.transaction/check_in?code=${dataAppointment.code}`
      `edelweiss.appointment.transaction/check_in${param}`
    );
    setLoadingGetAntrian(false);

    return data;
  };

  const isAllowPrint = (date) => {
    if (
      context.moment().format('YYYY-MM-DD') ===
      context.moment(date).format('YYYY-MM-DD')
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const stillActiveTime = (timeAvailable) => {
      const subtractTimeAvailable = context.moment(timeAvailable).subtract(15, 'minutes').format('YYYY-MM-DD HH:mm');
      const currentTime = context.moment();
      const date = context.moment(subtractTimeAvailable).diff(currentTime); // miliseconds

      if (date > 0) {
        return true;
      }
      return false;
    };

    if (dataAppointment.length > 0) {
      const endSchedule = `${context.moment().format('YYYY-MM-DD')} ${dataAppointment[0].doctor_schedule.end_hour}:${dataAppointment[0].doctor_schedule.end_minute}`
      console.log('endSchedule -10 minute', context.moment(endSchedule).subtract(10, 'minutes').format('YYYY-MM-DD HH:mm'))
      console.log('endSchedule', endSchedule)
      if (stillActiveTime(endSchedule)) {
        setIsAllowToCheckin(true);
      } else {
        setIsAllowToCheckin(false);
      }
    }
  }, [dataAppointment.length]);

  const selectAppointment = async (appointment) => {
    context.onlineCheckin.setSelectedPatientReg(appointment);
    // setLoadingGetAntrian(true);
    // context.regular.setOldPatient(false);

    // const checkin_antrian = await checkInPatient(
    //   `?dsid=${appointment.doctor_schedule.dsid}&no_rm=${convertIntToRm(
    //     appointment.pid
    //   )}&tgl_janji=${context
    //     .moment(appointment.tgl_janji)
    //     .format("YYYY-MM-DD")}`
    // );

    // setLoadingGetAntrian(false);

    // setIsModalGuideWhatsNextVisible(true);
    // startTimerGuide();

    // window.open(
    //   `/print-label.html?patient_name=${
    //     appointment.nama
    //   }&norm=<NO_RM>&date=${context
    //     .moment()
    //     .format("DD MMMM YYYY HH:mm:ss")}&clinic=${
    //     appointment.department[0].name
    //   }&docter=${appointment.person.name_real}&noantrian=${
    //     checkin_antrian.no_antrian
    //   }&lantai=${appointment.department[0].lantai}&area=${
    //     appointment.department[0].area
    //   }`,
    //   "name",
    //   "width=10,height=10"
    // );
  };

  // printAntrian pasien baru pid <=0
  const cetakAntrianNewPatient = async (appointment) => {
    context.regular.setKlinik({
      name: appointment.department[0]?.name,
      lantai: appointment.department[0]?.lantai,
      area: appointment.department[0]?.area,
    });
    setLoadingGetAntrian(true);
    const { data: checkin_new_patient } = await global.get(
      `web/no_antrian?did=${appointment.department[0]?.did}`
    );

    setLoadingGetAntrian(false);

    setIsModalGuideWhatsNextVisible(true);
    startTimerGuide();
    const dob = appointment.date_birth;
    const dateBirth = `${dob.split('-')[2]}/${dob.split('-')[1]}/${dob.split('-')[0]
      }`;

    const norm = 'Pasien Baru';

    window.open(
      `/print-label.html?patient_name=${appointment.nama
      }&norm=${`${dateBirth} | ${norm}`}&date=${context
        .moment()
        .format('DD MMMM YYYY HH:mm:ss')}&clinic=${appointment.department[0]?.name
      }&docter=${appointment.person.name_real}&noantrian=${checkin_new_patient.results[0]?.urutan
      }&lantai=${appointment.department[0]?.lantai}&area=${appointment.department[0]?.area
      }&petunjuk=Silahkan menuju Admission di`,
      'name',
      'width=10,height=10'
    );
  };

  if (isModalGuideWhatsNextVisible) {
    modalGuideWhatsNext = (
      <EuiModal onClose={() => null}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Langkah selanjutnya</h1>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody className='flex items-center justify-center'>
          <TemplatePrintPoliklinik>
            <h2 className='text-lg'>
              Silahkan menuju Admission di Lantai 1 Area Eksekutif
            </h2>
          </TemplatePrintPoliklinik>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiFlexGroup justifyContent='center'>
            <EuiFlexItem grow={false}>
              Menutup Otomatis dalam ({timeLeft} detik)
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    <div className='w-full h-screen py-24 mx-auto mt-24 overflow-y-auto'>
      {modalGuideWhatsNext}

      {context.onlineCheckin.bookingVia === 'web' && (
        <div className='py-12'>
          <div className='flex justify-center gap-3'>
            <img
              src={image1}
              className='h-32'
              alt='image1'
            />
            <div className='w-[16rem] leading-6 pt-6'>
              <b>Langkah pertama,</b> buka website{' '}
              <a>https://care.edelwiess.id</a> , dan lakukan{' '}
              <b>Lakukan Login</b>
            </div>
          </div>
          <div className='flex justify-center gap-3'>
            <div className='w-[16rem] leading-6 pt-6 text-right'>
              <b>Langkah kedua,</b> pada bagian <b>Navigasi</b>, Tap menu
              Transaksi
            </div>
            <img
              src={image2}
              className='h-32'
              alt='image2'
            />
          </div>
          <div className='flex justify-center gap-3'>
            <img
              src={image3}
              className='h-32'
              alt='image3'
            />
            <div className='w-[16rem] leading-6 pt-6'>
              <b>Langkah ketiga,</b> pada bagian <b>Tab di atas</b>, Tap menu
              Poliklinik. <br /> Lalu pilih <b>Janji Temu</b> yang sudah di
              Bayar
            </div>
          </div>
          <div className='flex justify-center gap-3'>
            <div className='w-[16rem] leading-6 pt-6 text-right'>
              <b>Langkah terakhir,</b> Scan <b>QRCode</b> yang tampil di layar
              HP Anda
            </div>
            <img
              src={image4}
              className='h-32'
              alt='image4'
            />
          </div>
        </div>
      )}
      {context.onlineCheckin.bookingVia === 'web' && (
        <>
          <EuiFormRow
            label='Kode registrasi'
            className='mx-auto'
            isInvalid={context.wasSearchMedrek && dataAppointment.length === 0}
            error={errorTransaction}
          >
            <TransactionInput
              id='invNumber'
              isLoading={isLoadingRegisterNumber}
              inputRef={invNumber_ref}
              value={context.regular.invNumber}
              onChange={handleInvNumber}
              onFocus={handleOnFocusInvNumber}
              // onBlur={() => setIsFocused(false)}
              name='invNumber'
              sparator='APTINV'
            />
          </EuiFormRow>
          {/* {isAllowPrint(dataAppointment[0].waktu_estimasi)} */}
          {dataAppointment.map((appointment) => {
            return (
              <EuiFlexItem
                key={appointment.id}
                className='w-full px-16 mx-auto mt-8 lg:w-1/2 md:w-1/2 md:px-0 lg:px-0'
              >
                {!isAllowPrint(appointment.appointment_date) && (
                  <h4 className='mx-auto mb-8 text-lg text-center text-red-500'>
                    Jika anda hadir tidak sesuai dengan hari appointment, kami
                    persilahkan untuk dapat menuju KiosK Registration Counter
                    dan infokan kepada petugas Admission/Pendaftaran kami
                    terkait pendaftaran Website yang sudah dilakukan.
                  </h4>
                )}
                <EuiCard
                  textAlign='left'
                  title={appointment.poli.name}
                  description=''
                  className={`${isAllowPrint(appointment.appointment_date)
                    ? 'opacity-100'
                    : 'opacity-30'
                    }`}
                  onClick={() => {
                    if (isAllowToCheckin) {
                      if (
                        isAllowPrint(appointment.appointment_date) &&
                        appointment.state === 'paid' &&
                        !loadingGetAntrian
                      ) {
                        printLabel(appointment);
                      }
                    }
                  }}
                  footer={
                    <>
                      <EuiSpacer size='m' />
                      {isAllowPrint(appointment.appointment_date) && (
                        <EuiFlexGroup justifyContent='center'>
                          <EuiFlexItem grow={false}>
                            {isAllowToCheckin ? (
                              <EuiButton
                                iconType={
                                  appointment.state === 'paid' && 'logstashOutput'
                                }
                                onClick={() => {
                                  if (appointment.state === 'paid') {
                                    printLabel(appointment);
                                  }
                                }}
                                isDisabled={loadingGetAntrian}
                                color='accent'
                                className={
                                  appointment.state !== 'paid' && 'opacity-50'
                                }
                              >
                                {appointment.state === 'paid' && 'Cetak antrian'}
                                {appointment.state === 'start' &&
                                  'Sudah melakukan Check In'}
                              </EuiButton>
                            ) : <span className='font-semibold text-red-500'>CLOSED ORDER</span>}
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      )}
                    </>
                  }
                >
                  <>
                    <EuiSpacer size='m' />
                    <EuiText grow={false}>
                      <dl className='eui-definitionListReverse'>
                        <dt>Dokter</dt>
                        <dd>{appointment.doctor[1]}</dd>
                        <dt>Pasien</dt>
                        <dd>{appointment.patient.name}</dd>
                        <dt>Waktu Janji</dt>
                        <dd>
                          {context
                            .moment(appointment.appointment_date)
                            .format('DD MMM YYYY, hh:mm')}
                        </dd>
                        <dt>Nomor Antrian</dt>
                        <dd className='text-3xl font-bold text-gray-600'>
                          {appointment.no_antrian}
                        </dd>
                      </dl>
                    </EuiText>
                  </>
                </EuiCard>
              </EuiFlexItem>
            );
          })}
        </>
      )}

      {/* WA */}
      {context.onlineCheckin.bookingVia === 'wa' && (
        <EuiFlexItem grow={false}>
          <EuiText
            grow={false}
            color=''
            className='mx-auto mb-12'
          >
            *Pastikan data dibawah ini terisi sesuai dengan Data perjanjian
            lewat Whatsapp atau Telp
          </EuiText>
        </EuiFlexItem>
      )}
      {context.onlineCheckin.bookingVia === 'wa' &&
        dataAppointment.length === 0 && (
          <>
            <EuiFormRow
              label='Nama depan'
              className='mx-auto'
              isInvalid={
                context.wasSearchMedrek && context.dataPatient === null
              }
              error={errorKTP}
            >
              <TextInput
                inputRef={full_name_ref}
                value={context.regular.fullName}
                name='fullname'
                onChange={handleFullName}
                onFocus={handleOnFocusFullName}
                autoComplete='off'
              />
            </EuiFormRow>
            {/* <EuiFormRow label="No. HP" className="mx-auto">
                  <IdentityNumberInput
                    inputRef={identity_number_ref}
                    onBlur={onBlur}
                    value={context.regular.identityNumber}
                    name="identityNumber"
                    onChange={handleIdentityNumber}
                    autocomplete="off"
                  />
                </EuiFormRow> */}
            <EuiFormRow
              label='Tanggal lahir'
              className='mx-auto'
              helpText='Format (dd-mm-yyyy)'
              isInvalid={errorDob.length}
              error={errorDob}
            >
              <DateInput
                inputRef={dob_ref}
                value={context.regular.dob}
                name='dob'
                onChange={handleDob}
              />
            </EuiFormRow>
            <div className='flex justify-center mt-12'>
              <EuiButton
                iconType='search'
                iconSide='right'
                onClick={checkNoRM}
                color='primary'
                // isDisabled={!isDobFilled || !isFullnameFilled}
                isLoading={searchingNoRM}
              >
                {searchingNoRM && 'Mohon tunggu'}
                {!searchingNoRM && 'Cari data'}
              </EuiButton>
            </div>
          </>
        )}

      {context.onlineCheckin.bookingVia === 'wa' &&
        dataAppointment.length > 0 && (
          <>
            <EuiFlexGrid
              gutterSize='xl'
              columns={2}
              className={`px-6 ${dataAppointment.length === 1 ? 'justify-center' : null
                }`}
            >
              {dataAppointment.map((appointment, index) => {
                return (
                  <EuiFlexItem key={index}>
                    <EuiCard
                      textAlign='left'
                      title={appointment.department[0]?.name}
                      description=''
                      onClick={() => {
                        if (isAllowToCheckin) {
                          if (appointment.pid <= 0) {
                            if (!loadingGetAntrian) {
                              context.regular.setOldPatient(false);
                              cetakAntrianNewPatient(appointment);
                            }
                          } else {
                            context.regular.setOldPatient(true);
                            // selectAppointment(appointment);
                            context.onlineCheckin.setSelectedPatientReg(
                              appointment
                            );
                            props.beforeNext();
                          }
                        }
                      }}
                      footer={
                        <>
                          <EuiSpacer size='m' />
                          <EuiFlexGroup justifyContent='center'>
                            <EuiFlexItem grow={false}>
                              {isAllowToCheckin ? (
                                <EuiButton
                                  iconType={
                                    appointment.pid <= 0
                                      ? 'logstashOutput'
                                      : 'arrowRight'
                                  }
                                  iconSide='right'
                                  onClick={() => {
                                    if (appointment.pid <= 0) {
                                      if (!loadingGetAntrian) {
                                        context.regular.setOldPatient(false);
                                        cetakAntrianNewPatient(appointment);
                                      }
                                    } else {
                                      context.regular.setOldPatient(true);
                                      // selectAppointment(appointment);
                                      context.onlineCheckin.setSelectedPatientReg(
                                        appointment
                                      );
                                      props.beforeNext();
                                    }
                                  }}
                                  color='accent'
                                  isLoading={loadingGetAntrian}
                                  isDisabled={loadingGetAntrian}
                                >
                                  {appointment.pid <= 0
                                    ? 'Cetak antrian'
                                    : 'Lanjut'}
                                </EuiButton>
                              ) : <span className='font-semibold text-red-500'>CLOSED ORDER</span>}
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </>
                      }
                    >
                      <>
                        {/* <EuiSpacer size='m' /> */}
                        <EuiText grow={false}>
                          <dl className='eui-definitionListReverse'>
                            <dt>Pasien</dt>
                            <dd>{appointment.nama}</dd>
                            <dt>Tanggal Lahir</dt>
                            <dd>{`${appointment.date_birth.split('-')[2]}-${appointment.date_birth.split('-')[1]
                              }-${appointment.date_birth.split('-')[0]}`}</dd>
                            <dt>Dokter</dt>
                            <dd>{appointment.person.name_real}</dd>
                            <dt>Tanggal Janji</dt>
                            <dd>
                              {context
                                .moment(appointment.tgl_janji)
                                .format('DD MMM YYYY, HH:mm')}
                            </dd>
                          </dl>
                        </EuiText>
                      </>
                    </EuiCard>
                  </EuiFlexItem>
                );
              })}
            </EuiFlexGrid>

            <div className='px-6 my-16 '>
              {/* <EuiFlexGroup justifyContent='center'>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  onClick={() => {
                    context.setDataPatient(null);
                    context.setWasSearchMedrek(false);
                    context.regular.setFullName("");
                    context.regular.setDob("");
                  }}
                >
                  Klik disini, jika data Salah!
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup> */}
              <EuiFlexGroup justifyContent='center'>
                <EuiFlexItem grow={false}>
                  <EuiText
                    grow={false}
                    color=''
                  >
                    *Ketika terdapat perubahan data alamat atau nama, silahkan
                    menghubungi call center/pendaftaran kami setelah berobat
                  </EuiText>
                  <EuiButtonEmpty
                    onClick={() => {
                      context.setDataPatient(null);
                      setDataAppointment([]);
                      context.setWasSearchMedrek(false);
                      context.regular.setFullName('');
                      context.regular.setDob('');
                    }}
                  >
                    klik disini untuk kembali ke halaman utama
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </>
        )}
    </div>
  );
};

export default Two;
